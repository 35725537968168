import { createSelector } from 'reselect';
import { getInstitutionData } from './selected_institutions';

export const getUser = state => state.user;

export const getUserUid = createSelector([getUser], user => user?.uuid ?? '');

export const getUserTours = createSelector([getUser], user => (user && user.tours ? user.tours.user_state : {}));

export const getUserPrivateData = createSelector([getUser], user => user.private_data || {});

export const getUserUpdateURL = createSelector([getUser], user => (user ? `/users/${user.uuid}` : ''));

export const getUserOauthProviderURL = createSelector([getUser], user => user.oauth_providers_url);

export const getHealthSpaces = createSelector([getUser], user => (user ? user.healthSpaces : []));

export const getSelectedHealthSpace = createSelector([getUser], user => (user ? user.selectedHealthSpace : undefined));

export const getAvatar = createSelector([getUser], user => ({
  name: user.private_data.name,
  src: user.private_data.avatar_url
}));

/**
 * Create a flat list of institutions in health spaces
 */
export const getHealthSpacesInstitutions = createSelector([getHealthSpaces], healthSpaces =>
  healthSpaces
    .filter(hs => hs.institutions)
    .map(hs =>
      hs.institutions.map(inst => ({
        healthSpace: hs,
        ...getInstitutionData(inst)
      }))
    )
    .flat()
);
